.react-datepicker {
  width: 380px !important;
  min-height: 380px !important;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  border-radius: 12px !important;
  border: none;
  box-shadow: 0px 0px 0.8rem 0px rgba(0, 0, 0, 0.2);
  overflow: hidden !important;

  transition: 0.4s;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: rgba(112, 112, 255, 0.08);
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 1.2rem;
  border-top-left-radius: 0px;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: rgba(112, 112, 255, 0.08);
}
.react-datepicker__month-container {
  width: 100% !important;
  height: 100% !important;

  display: flex;
  flex-direction: column;
  background-color: white;
}
.react-datepicker__month {
  flex: 1;
  width: 100%;
  margin: 4px 0px;
  align-items: center;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.6rem;
}
.react-datepicker__day-names {
  margin-bottom: -0.8rem;
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-around;
}
.react-datepicker__day-names,
.react-datepicker__week {
  width: 100%;
  display: flex;
  justify-content: space-around;

  height: 20% !important;
  align-items: center;
}
.react-datepicker__month {
  flex: 1;
  width: 100%;
}
.react-datepicker__day--keyboard-selected {
  background-color: rgba(112, 112, 255, 0.8);
  width: 100%;
  aspect-ratio: 1;
}
.react-datepicker__triangle {
  background-color: rgba(112, 112, 255, 0.5);
  transform: translate(10rem, 0px) !important;
}
.react-datepicker__header {
  background-color: rgba(112, 112, 255, 1);
}
.react-datepicker__day--in-range {
  background-color: rgba(112, 112, 255, 0.4);
}
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(112, 112, 255, 0.4);
}
.react-datepicker__day {
  width: 80% !important;

  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: rgba(112, 112, 255, 0.08);
}
.react-datepicker__input-container {
  display: flex;
  width: 100% !important;
}
.react-datepicker-wrapper {
  display: flex;
  width: 100% !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 14px;
  margin-bottom: 14px;
}
.react-datepicker__navigation {
  top: 18px;
}
.react-datepicker__navigation--previous {
  left: 10px;
}

.react-datepicker__navigation--next {
  right: 10px;
}
.react-datepicker__day-names,
.react-datepicker__week {
  width: calc(100% - 20px);
  padding: 0px 10px;
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.react-datepicker__portal .react-datepicker__day-name {
  color: white;
  font-weight: 600;
}
