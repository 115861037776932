.rcs-custom-scroll .rcs-custom-scrollbar {
  opacity: 1;
}
.rcs-custom-scroll {
  height: 100%;
  width: 100%;
  display: flex;
}
.rcs-inner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.rcs-outer-container {
  height: 100%;
  width: 100% !important;
  display: flex;
}
