.root-gallery {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit !important;
}
.carousel-root {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;

  color: inherit !important;
}

.carousel .control-dots .dot {
  background-color: #7070ff;
}
.carousel .thumb {
  height: 8rem;
  color: inherit !important;
}
.carousel .slider-wrapper {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.carousel .slide {
  width: 100% !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 2px solid rgba(112, 112, 255, 1);
  border-radius: 4px;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  width: 100% !important;
  display: flex !important;
  height: 100% !important;
}
.carousel .carousel-status {
  color: inherit;
  font-size: max(1.4rem, 14px);
  left: 4px;
  top: 4px;
  margin: 0px;
  text-shadow: none;
  font-weight: 600;
  font-family: Inter;
}

.carousel .thumbs-wrapper {
  width: calc(100% - 40rem);
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 10px 20px !important;
  border-radius: 4px;
  margin: 4px;
}
.carousel.carousel-slider .slider-wrapper .axis-horizontal {
  height: unset !important;
  flex: 1;
}

.carousel.carousel-slider {
  width: calc(100% - 80px) !important;
  height: calc(100% - 80px) !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px !important;
  color: inherit !important;
}
.carousel .slider-wrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex;
}
.carousel .slide img {
  width: 100%; /* or any custom size */
  height: 100%;
  object-fit: contain;
}
.carousel .control-next.control-arrow:before {
  border-left-color: #7070ff;
  border-left-width: 14px;
  border-top-width: 14px;
  border-bottom-width: 14px;
}
.carousel .control-prev.control-arrow:before {
  border-right-color: #7070ff;
  border-right-width: 14px;
  border-top-width: 14px;
  border-bottom-width: 14px;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
