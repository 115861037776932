.section2 {
	background-color: #D6D9DF !important;
}

.projectSection {
	margin-left: 20px;
	margin-top: 10px;
}

.measurements-container .MuiTableCell-root {
	padding: 10px !important;
}
.measurements-container .MuiSelect-select {
	padding-top: 12px;
	padding-bottom: 12px;
}

.measurement-tools {
	margin-bottom: 25px;
}

.konva-container {
	background-color: white;
	height: 80vh;
	margin-top: 25px;
}

.header-box {
	height: 65px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background-color: white;
}

.measurement-box {
	height: 50vh;
	background-color: white;
}

.measurement-tools-list {
   height: 28px;
   width: 54px;
   background-color: #e9e1ff;
   margin-left: 2px;
   justify-content: center;
   align-items: center;
   position: relative;
   cursor: pointer;
}

.measurement-tools-list.active {
	background-color: #bfa8ff;
}

.measurement-icons {
	padding-left: 20px;
	padding-top: 8px;
}

.measurement-tools-list b {
	font-size: 8px;
	left: 10px;
	position: absolute;
	top: 33px;
}


.measurement-tools-list:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.measurement-tools-list:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.measurement-tools-list.input-height {
	width: 160px;
	font-size: 11px;
	font-family: "Roboto";
}

.right-end-text {
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 10px;
	padding-left: 9px;
}

.icon-text-field {
	margin-left: 5px;
	height: 10px;
	width: 35px;
	position: relative;
	top: 2px;
	font-size: 10px;
}

.measurement-tools-container {
	justify-content: center;
	position: relative;
	margin-left: 20%;
}

.measurement-tools-container ul {
	display: flex;
	list-style: none;
}

.measurement-header-icon-box {
	display: flex;
	list-style: none;
	border-top: 1px solid #9974fe;
	border-bottom: 1px solid #9974fe;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 11px;
}

.icon-line-above {
	margin-right: 5px;
	padding-left: 5px;
}

.stage-canvas {
	height: 566px;
}

.textColor {
	color: #9974fe !important;
}

.tableHeader {
	padding: 10px;
}

.measurementHead  {
	font-weight: 600;
	text-align: center;
	padding-top: 5px;
}

.tableHeaderIcons {
	width: 24px;
	height: 24px;
}

.tableBodyIcons {
	width: 20px;
	height: 20px;
}

.dragContent {
	width: 10%;
	cursor: grab;
}

.no-cursor {
	cursor: auto;
}

/* .plan-content {
	height: inherit;
} */

.measurementName {
	padding: 10px !important;
}

.unit {
	width: 32%;
}

.active {
	background-color: #f5f5f7;
}

.confirmation-pop-up {
	background-color: rgb(187, 211, 247);
	min-width: 100%;
	padding: 10px;
	margin-left: 0px !important;
	margin-top: 0px !important;
}

.scale-input-box {
	right: 5%;
	position: absolute;
	padding: inherit !important;
}

.instruction-box {
	padding: inherit;
}

.clickable-icon {
	cursor: pointer;
}

#scaleInputLabel {
	margin-right: 50px;
}

.MuiGrid-root .measurement-tools .measurement-icons{
	color: #1521B6;
}

.checkIcon {
	color: #9974fe;
	margin-right: 5px;
}

.invert {
	filter: brightness(0) invert(1);
}

.MuiButtonBase-root{
	border-radius: 8px !important;
}

.pulse-alert{
	animation: animate 0.8s linear infinite
}


@keyframes animate {
	0% {
		transform: scale(1);
	}
	50%{
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}