.react-pdf__Document {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.react-pdf__Page {
  display: flex;
  height: 100%;
  justify-content: center;
}
