@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Inter:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Serif:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Work+Sans:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Indie+Flower:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Sarina:regular,bold,italic&wght@200..900&display=swap&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "DearJoe4";
  src: local("DearJoe4"), url("./Fonts/dearJoe4.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Nostromo Regular";
  src: local("NostromoRegular"),
    url("./Fonts/NostromoRegular/NostromoRegular-Medium.otf") format("opentype");
  font-weight: normal;
}

/* changes a default 16px font size to 10px */
html {
  font-size: 62.5%;
}
@media (min-width: 300px) {
  html {
    font-size: 30%;
  }
}

@media (min-width: 0px) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 767px) {
  html {
    font-size: 55%;
  }
}
@media (min-width: 850px) {
  html {
    font-size: 59%;
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 62.5%;
  }
}
@media (min-width: 1280px) {
  html {
    font-size: 65%;
  }
}
@media (min-width: 1320px) {
  html {
    font-size: 67.5%;
  }
}

/* Previous Fonts */
/*@font-face {*/
/*  font-family: "Roboto Mono";*/
/*  src: url("./Fonts/Roboto_Mono/static/RobotoMono-Regular.ttf")*/
/*    format("truetype");*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-ExtraLight.ttf") format("truetype");*/
/*  font-weight: 200;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-Thin.ttf") format("truetype");*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-Light.ttf") format("truetype");*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-Regular.ttf") format("truetype");*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-Medium.ttf") format("truetype");*/
/*  font-weight: 500;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-SemiBold.ttf") format("truetype");*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-Bold.ttf") format("truetype");*/
/*  font-weight: 700;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-ExtraBold.ttf") format("truetype");*/
/*  font-weight: 800;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: url("./Fonts/Inter/static/Inter-Black.ttf") format("truetype");*/
/*  font-weight: 900;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "IBM Plex Sans";*/
/*  src: local("IBMPlexSans-Regular"),*/
/*    url("./Fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype");*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: local("IBMPlexMono-Regular"),*/
/*    url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf") format("truetype");*/
/*  font-weight: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: local("IBMPlexMono-SemiBold"),*/
/*    url("./Fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf") format("truetype");*/
/*  font-weight: 600;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Thin.ttf") format("truetype");*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf") format("truetype");*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf") format("truetype");*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf") format("truetype");*/
/*  font-weight: 500;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf") format("truetype");*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Bold.ttf") format("truetype");*/
/*  font-weight: 700;*/
/*  font-style: normal;*/
/*}*/

/*SERIF*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: local("IBMPlexSerif-SemiBold"),*/
/*    url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-SemiBold.ttf") format("truetype");*/
/*  font-weight: 600;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-Thin.ttf") format("truetype");*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-Light.ttf") format("truetype");*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-Regular.ttf") format("truetype");*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-Medium.ttf") format("truetype");*/
/*  font-weight: 500;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-SemiBold.ttf")*/
/*    format("truetype");*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Serif";*/
/*  src: url("./Fonts/IBM_Plex_Serif/IBMPlexSerif-Bold.ttf") format("truetype");*/
/*  font-weight: 700;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-ExtraBold.ttf") format("truetype");*/
/*  font-weight: 800;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Mono";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Black.ttf") format("truetype");*/
/*  font-weight: 900;*/
/*  font-style: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "Work Sans";*/
/*  src: local("WorkSans-Regular"),*/
/*    url("./Fonts/Work_Sans/static/WorkSans-Medium.ttf") format("truetype");*/
/*  font-weight: normal;*/
/*}*/
/*@font-face {*/
/*  font-family: "IBM Plex Sans Condensed";*/
/*  src: local("IBMPlexSansCondensed-Regular"),*/
/*    url("./Fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf")*/
/*      format("truetype");*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "Sarina";*/
/*  src: local("Sarina-Regular.ttf"),*/
/*    url("./Fonts/Sarina/Sarina-Regular.ttf") format("truetype");*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: "IBMPlexSans-Light";*/
/*  src: url("./Fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf");*/
/*}*/

/*@font-face {*/
/*  font-family: "IBMPlexSans-Regular";*/
/*  src: url("./Fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");*/
/*}*/

/*@font-face {*/
/*  font-family: "IBMPlexSans-Medium";*/
/*  src: url("./Fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");*/
/*}*/

/*@font-face {*/
/*  font-family: "IBMPlexMono-Regular";*/
/*  src: url("./Fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf");*/
/*}*/

/*@font-face {*/
/*  font-family: "NostromoRegular-Light";*/
/*  src: url("./Fonts/NostromoRegular/NostromoRegular-Light.otf");*/
/*}*/
/*@font-face {*/
/*  font-family: "Indie Flower";*/
/*  src: url("./Fonts/Indie_Flower/IndieFlower-Regular.ttf") format("truetype");*/
/*  font-weight: normal;*/
/*}*/
